
.vendor-sec-sec-scrl-tbs {
    display: flex;
    height: auto;
    flex-direction: column;
    width: 100%;
    background-color: black;
    .tab-container {
        height: 61px;
        width: 100%;
        position: sticky;
        padding-top: 20px;
        padding-left: 62px;
        padding-right: 42px;
        top: 80px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 70px;
        backdrop-filter: blur(20px) saturate(1.8);
        // background: rgba(60, 65, 66, 0.2901960784);
        background-color: black;
        overflow-x: auto;
        overflow-y: hidden;
        z-index: 2;
    }
    .section-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .section {
            height: 552px;
            display: flex;
            flex-direction: row;
            .txt-container {
                width: 48%;
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                .flx {
                    display: flex;
                    flex-direction: column;
                    padding: 0px 28px 34px 68px;
                }
                .flx:last-child {
                    display: flex;
                    flex-direction: column;
                    padding: 0px 28px 0px 68px;
                }
                span.top-heading {
                    font-size: 36px;
                    color: white;
                    line-height: 50px;
                    font-weight: 400;
                    text-align: left;
                }
                span.paragraph {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 26px;
                    color: #FFFFFF99;
                    text-align: left;
                }
                span.heading {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    text-align: left;
                    color: #FFFFFF;
                }
            }
            .img-container {
                width: 48%;
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                figure {
                    height: auto;
                    width: auto;
                    display: flex;
                    // justify-content: center;
                }
            }
        }
        .reverse {
            flex-direction: row-reverse;
        }
    }
    /* Tab Buttons */
    .tab {
        margin: 0 10px;
        cursor: pointer;
        background-color: black;
        color: #919696;
        height: 48px;
        width: auto;
        min-width: 198px;
        max-width: 224px;
        border: none;
        border-bottom: 1px solid #3C4142;
        display: flex;
        justify-content: center;
        align-items: center;
        .tb-txt {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #919696;
            width: auto;
            margin-left: 8px;
            margin-right: 8px;
        }
    }
    
    .tab.active {
        background-color: #171819;
        color: #FFFFFF;
        border-radius: 10px;
        border: none;
        height: 61px;
        .tb-txt {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            width: 154px;
            padding-bottom: 10px;
            border-bottom: 3px solid white;
            width: 100%;
        }
        
    }
}
@media (max-width: 768px) {
    .vendor-sec-sec-scrl-tbs {
        .conta_iner {
            width: 100%;
        }
        .section-container .section .txt-container .flx {
            padding: 0px 28px 34px 30px;
        }
        .section-container .section .txt-container .flx:last-child {
            padding: 0px 28px 64px 30px;
        }
        .section-container .section .txt-container span.top-heading {
            font-size: 26px;
        }
    }
}
@media (max-width: 600px) {
    .vendor-sec-sec-scrl-tbs {
        .section-container .section {
            flex-direction: column;
        }
        .section-container .section .txt-container {
            width: 100%;
        }
        .section-container .section .img-container {
            width: 100%;
            padding-right: 18px;
        }
        .section-container .section .txt-container .flx:last-child {
            padding: 0px 18px 0px 18px
        }
        .section-container .section .txt-container .flx {
            padding: 0px 18px 24px 18px;
        }
        .section-container .section {
            height: 100vh;
        }
        .section-container .section .txt-container span.top-heading {
            line-height: 24px;
            font-size: 20px;
        }
        .section-container .section .txt-container span.heading {
            font-size: 16px;
            line-height: 18px;
        }
        .section-container .section .txt-container span.paragraph {
            font-size: 12px;
            line-height: 18px;
        }
    }
}