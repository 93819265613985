@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import "aos/dist/aos.css";

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-Black.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-Thin.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-ExtraBold.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-Bold.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-SemiBold.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-Medium.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-ExtraLight.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("../public/assets/fonts/KumbhSans-Light.woff2") format("woff2"),
    url("../public/assets/fonts/KumbhSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Questa Grande";
  src: url("../public/assets/fonts/QuestaGrande-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/QuestaGrande-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@mixin content-limit($line) {
  white-space: normal;
  display: -webkit-box !important;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin img_contain($object: contain) {
  width: 100%;
  height: 100%;
  object-fit: $object;
}

@mixin d_flx($align: "center", $justy: "space-between", $gap: 0) {
  display: flex;
  align-items: $align;
  justify-content: $justy;
  flex-wrap: wrap;
  gap: $gap;
}

.d_flx {
  @include d_flx(center, space-between, "");
}

$c_primary: #070707;
$c_secondary: #3c4142;
$c_danger: #d92d20;
$c_gray: #bdbdbd;
$dark_black: #1d1d1d;
$c_success: #079455;
$c_dark_border: #cbcbcb;
$c_border: #e0e0e0;
$c_black: #000000;
$c_white: #ffffff;
$f_body: "Kumbh Sans", sans-serif;
$f_heading: "Questa Grande", sans-serif;
$transition: all 0.3s ease-in-out 0s;
$shadow1: 0px 20px 16px #00000014;
$shadow2: 0px 2px 16px #00000014;

// ::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;

//   &-thumb {
//     background: $c_primary;
//   }

//   &-track {
//     background-color: $c_black;
//   }
// }

* {
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
  font-family: $f_body !important;
}

html {
  // scroll-behavior: smooth;
}

body {
  background-color: $c_white;
  cursor: none;

  &[style*="padding-right"] {
    .site_header {
      padding-right: 5px;
    }
  }
}

a {
  color: $c_primary;
  transition: $transition;

  &:hover {
    color: $c_primary;
  }

  &,
  &:not(.btnn):hover {
    text-decoration: none;
  }
}

b,
strong {
  font-weight: 700;
}

.heading-font {
  font-family: $f_heading !important;
}

:is(h1, h2, h3, h4, h5, h6) {
  color: $c_secondary;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;

  &.fw_med {
    font-weight: 500;
  }
}

:where(h1, h2, h3, h4, h5, h6):not(:last-child) {
  margin-bottom: 15px;
}

h1,
.hd_1 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 48px;
  font-weight: 800;
}

h2,
.hd_2 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 40px;
  font-weight: 800;
}

h3,
.hd_3,
.hd_3 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  font-weight: 800;
}

h4,
.hd_4 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 30px;
}

h5,
.hd_5 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 26px;
}

h6,
.hd_6 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 22px;
}

.hd_7,
.hd_7 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 20px;
}

:is(body, p, li) {
  color: $c_primary;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  font-family: $f_body;
}

:is(p, li) {
  margin: 0;

  small {
    font-size: 16px;
    display: block;
  }
}

:where(p, ul, ol, li):not(:last-child) {
  margin-bottom: 15px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  line-height: 0;
}

.u {
  &_spc,
  &t_spc {
    padding-top: 80px;
  }

  &_spc,
  &b_spc {
    padding-bottom: 80px;
  }

  &h_spc,
  &ht_spc {
    padding-top: 60px;
  }

  &h_spc,
  &hb_spc {
    padding-bottom: 60px;
  }
}

.bg_grey {
  background-color: $c_secondary;
}

.c_white {
  color: $c_white;
}

.bg_white {
  background-color: $c_white;
}

.c_black {
  color: $c_black;
}

.bg_black {
  background-color: $c_black;
}

.c_danger {
  color: $c_danger;
}

.c_success {
  color: $c_success;
}

.text_center {
  text-align: center;
}

.d_block {
  display: block;
}

.w {
  &_50 {
    width: 50%;
  }

  &_100 {
    width: 100%;
  }
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_50 {
  margin-bottom: 50px;
}

// Grid | Start
:is(.gap_p, .gap_m) {
  // --gap_x: 30px;
  // --gap_y: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gap_y) * -1);
  margin-left: calc(var(--gap_x) * -1);
  height: 100vh;
  &.aic {
    align-items: center;
  }

  &.jcsb {
    justify-content: space-between;
  }

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--gap_y);
  }
}

.gap {
  &_p {
    > * {
      padding-left: var(--gap_x);
    }

    .w_25 {
      width: 25%;
    }

    .w_50 {
      width: 50%;
    }

    .w_100 {
      width: 100%;
    }
  }

  &_m {
    > * {
      margin-left: var(--gap_x);
    }

    .w_25 {
      width: calc(25% - var(--gap_x));
    }

    .w_50 {
      width: calc(50% - var(--gap_x));
    }

    .w_100 {
      width: calc(100% - var(--gap_x));
    }
  }
}

.sb_hd {
  h2 {
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
  }

  p {
    color: #fff;
    font-size: 14px;
  }
}

.dark_bg {
  background-color: $dark_black;
  padding: 150px;
}

// Button | Start
.btnn,
button.MuiButton-root {
  gap: 10px;
  font-size: 16px;
  text-transform: uppercase !important;
  font-weight: 500;
  min-height: 60px;
  padding: 5px 40px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  box-shadow: none !important;
  transition: $transition;
  border: 1px solid transparent;
  letter-spacing: 0px;
  position: relative;
  text-indent: 0px;
  overflow: hidden;
  cursor: pointer;
  line-height: 1;
  z-index: 1;

  &::before {
    content: "";
    animation: slide 2s infinite;
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  @keyframes slide {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }
}

.disabled {
  pointer-events: none; //This makes it not clickable
  opacity: 0.6; //This grays it out to look disabled
}

.opt_fields {
  > div {
    justify-content: center;
    gap: 10px;
  }

  input {
    width: 60px !important;
    height: 60px;
    font-size: 28px;
    font-weight: 500;
    border: 1px solid #a8a9ac;
    color: #000;
    border-radius: 6px;
  }
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 13px !important;
  margin: 0 !important;
  font-weight: 400;
  padding-top: 5px;
  margin-left: 2px !important;
}

.upload-profile .action {
  width: 26px;
  height: 26px;
  color: var(--c_white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #070707;
  background-color: $c_white;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  cursor: pointer;
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 60px;
    height: 60px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.btnn {
  &.sm {
    font-size: 16px;
    min-height: 50px;
  }

  &.btn_primary {
    color: $c_white;
    background-color: $c_secondary;
    border-color: $c_secondary;

    &:is(:hover, :focus) {
      color: $c_secondary;
      background-color: $c_white;
      border-color: $c_secondary;
    }

    &.br {
      color: $c_secondary;
      background-color: $c_white;
      border-color: $c_secondary;

      &:is(:hover, :focus) {
        color: $c_white;
        background-color: $c_secondary;
        border-color: $c_secondary;
      }
    }
  }

  &.btn_white {
    color: $c_secondary;
    background-color: $c_white;
    border-color: $c_white;
    border-radius: 0;
    min-width: 162px;
    min-height: 50px;

    &:hover {
      background: #ffffffc4;
      border-color: transparent;
    }
  }
}

button.btnn.btn_white {
  span {
    right: -8px;
    position: relative;
    transition: right 0.4s, transform 0.4s;
    will-change: right, padding;
  }

  &::after {
    background-image: url("../public/assets/images/arrow-right.svg");
    content: "";
    position: relative;
    left: 0;
    right: 0;
    width: 18px;
    height: 14px;
    transition: $transition;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.4s, transform 0.4s;
    will-change: opacity;
  }

  &:hover {
    &::after {
      display: block;
      transition: $transition;
      transform: translate3d(4px, 0, 0);
      opacity: 1;
    }

    span {
      right: 0;
      transform: translate3d(-4px, 1px, 0);
    }
  }
}

button.MuiButton-root {
  &.MuiButton-textSizeSmall,
  &.MuiButton-outlinedSizeSmall {
    font-size: 16px;
    min-height: 50px;
  }

  &.MuiButton-textPrimary {
    color: $c_white;
    background-color: $c_secondary;
    border-color: $c_secondary;

    &:is(:hover, :focus) {
      color: $c_secondary;
      background-color: $c_white;
      border-color: $c_secondary;
    }
  }

  &.MuiButton-outlinedPrimary {
    color: $c_secondary;
    background-color: $c_white;
    border-color: $c_secondary;

    &:is(:hover, :focus) {
      color: $c_white;
      background-color: $c_secondary;
      border-color: $c_secondary;
    }
  }
}

.btnn_icon {
  font-size: 18px;
  font-weight: 500;
  min-height: 46px;
  padding: 0 60px 0 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  box-shadow: none !important;
  border: 1px solid transparent;
  transition: $transition;
  border-radius: 40px;
  letter-spacing: 0px;
  position: relative;
  text-indent: 0px;
  overflow: hidden;
  cursor: pointer;
  line-height: 1;
  z-index: 1;

  &::before {
    content: "";
    animation: slide 2s infinite;
    background: linear-gradient(
      90deg,
      #40ebf100 0,
      #fffefd8f 50%,
      #fffefd47 69%,
      #40ebf100
    );
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  @keyframes slide {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }

  svg {
    top: 50%;
    right: 3px;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    background-color: $c_white;
    transition: $transition;
    border-radius: 50%;
    color: $c_secondary;
    padding: 10px;
  }

  &.btn_primary {
    color: $c_white;
    background-color: $c_primary;
    border-color: $c_primary;
  }

  &:hover {
    padding: 0 22px 0 60px;

    svg {
      right: calc(100% - 43px);
    }
  }
}

// Form | Start
.form {
  .gap_p,
  .gap_m {
    --gap_x: 20px;
    --gap_y: 20px;

    .control_group {
      margin-bottom: 0;
    }
  }
}

label {
  display: block;
  color: $c_secondary;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 5px;

  &.error {
    position: absolute;
    top: 100%;
    left: 0;
    color: $c_danger;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.2px;
    margin: 2px 0 0;
  }

  &.checkbox_label {
    font-size: 16px;
    color: $c_primary;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    transition: $transition;
    cursor: pointer;
    margin: 0;
    gap: 8px;
  }

  &.MuiFormControlLabel-root {
    margin: 0;
  }
}

input,
select,
textarea,
textarea.MuiOutlinedInput-input,
input.MuiInputBase-input,
input.MuiInputBase-input:-webkit-autofill,
.control_group .MuiSelect-select,
.form .react-tel-input .form-control {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: $c_secondary;
  border: none;
  padding: 10px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  outline: none !important;
  box-sizing: border-box;
  box-shadow: none;
  border-bottom: 1px solid $c_border;
}

input,
input.MuiInputBase-input,
.form .react-tel-input .form-control {
  height: 50px;
  padding: 0 0 0;
}

textarea,
textarea.MuiOutlinedInput-input {
  padding: 15px 20px;
  border-radius: 25px;
}

.form .react-tel-input {
  .form-control {
    padding-left: 60px;
  }

  .selected-flag {
    padding: 0 0 0 15px;

    .flag {
      transform: scale(0.8);

      .arrow {
        left: 32px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;

        &:not(.up) {
          border-top: 6px solid $c_secondary;
        }

        &.up {
          border-bottom: 6px solid $c_secondary;
        }
      }
    }
  }

  .country-list {
    width: 300px;
    max-height: 250px;

    .country {
      font-size: 14px;
      font-weight: 600;
      color: $c_primary;
      padding: 10px 10px 10px 46px;
      margin-bottom: 0;
    }

    .flag {
      top: 8px;
      left: 12px;
      margin-right: 0;
      margin-top: 0;
    }
  }
}

input:is([type="checkbox"], [type="radio"]) {
  width: 24px;
  height: 24px;
  min-width: 24px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  padding: 0;
  color: $c_white;
  accent-color: $c_primary;
  cursor: pointer;

  &:checked::before {
    transition: $transition;
    line-height: 1;
  }
}

input[type="checkbox"],
.checkbox_label .MuiCheckbox-root .MuiSvgIcon-root {
  border-radius: 4px;
  border: 1px solid $c_secondary;
  background-color: $c_secondary;
}

input[type="checkbox"]:checked,
.checkbox_label .MuiCheckbox-root .MuiSvgIcon-root[data-testid="CheckBoxIcon"] {
  background-color: $c_primary;
  border-color: $c_primary;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  color: $c_white;
}

.checkbox_label {
  :is(.MuiRadio-root, .MuiCheckbox-root) {
    position: relative;
    padding: 0;
  }

  .MuiCheckbox-root {
    .MuiSvgIcon-root path {
      display: none;
    }

    &.Mui-checked::after {
      content: "\2714";
      position: absolute;
      inset: 0;
      color: $c_white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }

  .MuiRadio-root {
    .MuiSvgIcon-root {
      opacity: 0;
    }

    input[type="radio"] {
      opacity: 1;
      border-radius: 50%;
      border: 1px solid #989898;
      background-color: $c_white;
    }

    &.Mui-checked {
      input[type="radio"] {
        border-color: $c_primary;

        &::before {
          content: "";
          height: 12px;
          width: 12px;
          line-height: 1;
          border-radius: 50%;
          background-color: $c_secondary;
        }
      }
    }
  }
}

.checkbox_label select,
.control_group .MuiSelect-select {
  height: 50px !important;
  padding: 0 40px 0 20px !important;
  line-height: 50px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  appearance: none;
}

.control_group {
  .MuiSelect-select {
    &[aria-expanded="true"] {
      border-color: $c_primary;
    }

    & ~ :is(fieldset, .MuiSvgIcon-root) {
      display: none;
    }

    &:focus {
      border-radius: 40px;
    }
  }

  .opt_fields {
    > div {
      justify-content: space-between;
    }

    input {
      width: 70px !important;
      height: 70px;
      font-size: 25px;
    }
  }

  .MuiInputBase-root {
    width: 100%;
  }
}

.MuiList-root li {
  font-size: 14px;
  font-weight: 500;
  color: $c_secondary;
  line-height: 1.3;

  &.Mui-selected,
  &.Mui-selected:hover {
    color: $c_white;
    background-color: $c_primary;
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

textarea {
  height: 100px;
  padding: 10px 20px;
  border-radius: 10px;
  resize: none;
}

:is(input, select, textarea, input.MuiInputBase-input) {
  &::placeholder {
    color: $c_secondary;
    font-weight: 500;
    opacity: 0.8;
  }

  &:focus {
    border-color: $c_primary;
  }

  &.empty_value {
    border-color: $c_danger;
  }
}

.form_btn {
  margin-top: 25px;

  button {
    width: 100%;
  }
}

.control_group {
  margin-bottom: 20px;
  position: relative;

  &_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiInputBase-hiddenLabel .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiFormControl-root.MuiTextField-root > .MuiInputBase-root {
    padding: 0;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        font-size: 20px;
      }
    }
  }
}

.content {
  padding-top: 60px;
  min-height: calc(100vh - 410.39px);
}

.conta_iner {
  width: 90%;
  // max-width: 1170px;
  margin-inline: auto;

  @media screen and (max-width: 767.98px) {
    .conta_iner {
      width: 100%;
      padding: 0 15px;
    }
  }
}
.flx-row {
  display: flex;
  justify-content: space-between;
}

.bg_dark {
  background-color: $dark_black;
  padding: 150px 0;

  &.pb_0 {
    padding-bottom: 0;
  }
}
.login-page.full-width {
  &::before {
    content: "";
    background: $c_black;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1),
      opacity 0.8s ease-in-out;
    transform: translateX(0%);
  }
}
// login page
.login-page {
  min-height: 100vh;
  position: relative;
  // padding: 1px 0;

  &::before {
    content: "";
    background: $c_black;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .login_lhs {
    position: relative;
    padding-top: 32px;
    padding-left: 0px;
    padding-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // &::after {
    //   content: "";
    //   // background-image: url(../public/assets/images/car2.png);
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: contain;
    //   width: 100%;
    //   height: 231px;
    //   display: block;
    //   // margin-left: -11%;
    // }
  }
  .login_lhs.full-width {
    position: relative;
    // margin-top: 32px;
    padding-left: 0px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #000;
    transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1),
      opacity 0.8s ease-in-out;
    transform: translateX(0%);
    // &::after {
    //   content: "";
    //   // background-image: url(../public/assets/images/car2.png);
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: contain;
    //   width: 100%;
    //   height: 40vh;
    //   display: block;

    //   // margin-left: -11%;
    // }
  }

  .login_rhs {
    // max-height: 150vh;
    // overflow: auto;
    // padding: 20px 0;

    .login-box {
      max-width: 480px;
      margin: 0 auto;

      .or-box {
        position: relative;
        margin: 50px 0;
        min-height: 50px;

        &::before {
          content: "";
          background: #e0e0e0;
          height: 1px;
          width: 100%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          padding: 0 15px;
          font-weight: 700;
        }
      }

      .MuiFormControl-root {
        width: 100%;

        .checkbox_label {
          flex-direction: row;
          justify-content: space-between;
        }

        label {
          width: 48%;
          border-bottom: 1px solid $c_border;
          padding: 15px 0;

          .MuiFormControlLabel-label {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.varient-box.vendor {
  li {
    margin: 32px 0px 0px 0px !important;
  }
  h2 {
    margin-bottom: 15px;
  }
}
.varient-box {
  // max-width: 70%;
  width: 485px;
  position: relative;
  z-index: 1;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 63%;
  span.sub-heading {
    font-size: 24px;
    color: #ffffff;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    padding-top: 36px;
  }

  li {
    display: flex;
    gap: 12px;
    margin: 15px 0px 0px 0px !important;

    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      font-size: 18px;
      color: $c_white;
      font-weight: 400;
      margin-bottom: 5px;
    }

    p {
      color: $c_gray;
      font-size: 16px;
    }

    figure {
      margin-top: 10px;
    }
  }
}

.login-heading {
  text-align: center;
  margin: 30px 0;

  h2 {
    font-size: 30px;
    color: $c_black;
    margin-bottom: 5px;
  }

  p {
    color: $c_secondary;
    font-weight: 500;
    font-size: medium;
  }
}

.tab-area {
  margin-top: 100px;

  .MuiTabs-flexContainer {
    justify-content: center;

    button {
      font-size: 24px;
      text-transform: none;
      padding: 20px 60px;
      opacity: 0.5;
    }

    .Mui-selected {
      color: $c_primary;
      opacity: 1;
    }

    .css-1aquho2-MuiTabs-indicator {
      height: 5px;
      background-color: #000000;
    }
  }
}

.mrs_box {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
  margin-bottom: 50px;

  li {
    margin: 0;
    padding: 9px;
    border: 1px solid $c_border;
    border-radius: 5px;
    width: 69px;
    text-align: center;
    cursor: pointer;

    &:hover,
    &.acitve {
      border-color: $c_primary;
    }
  }
}

.upload-profile {
  width: 108px;
  height: 108px;
  background: $c_secondary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
  position: relative;

  > figure {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .cover_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  input {
    font-size: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.upload_proof {
  border: 1px dashed $c_border;
  padding: 20px;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 16px;

  figure {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    font-size: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}

.crs_icn {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  right: 30px;
}
figure.crs_icn-mbl {
  position: absolute;
  top: 30px;
  right: 30px;
  background: white;
  height: 26px;
  border-radius: 50%;
  width: 26px;
}

.scl_icn_mn {
  margin-top: 10px;

  h3 {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
  }

  .scl_icn {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 20px;

    li {
      margin: 0;
    }
  }
}

.modal-main {
  .css-1wnsr1i {
    border: none !important;
    border-radius: 20px;
    padding: 50px 70px !important;
    width: 36% !important;
    position: relative;
  }

  h2 {
    font-size: 32px;
    font-family: $f_heading !important;
    color: $c_black;
    text-align: center;
    line-height: 1.2;
    font-weight: 600;
  }

  .modal-btn {
    margin-top: 50px;

    button {
      width: 100%;
    }
  }

  .crs_icn {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

// header csss= start
.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  appearance: none;
  /* Remove default arrow */
  border-radius: 4px;
  color: $c_white;
  cursor: pointer;
  box-shadow: none;
}

.custom-select select option {
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 25px;
  color: #000000;
}

.lang-select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-select select option[value="1"] {
  background-image: url("../public/assets/images/eng.png");
}

.custom-select select option[value="2"] {
  background-image: url("../public/assets/images/eng.png");
}

.bg-black {
  &::before {
    content: "";

    width: 100%;
    height: 80px;
    position: absolute;
    -webkit-backdrop-filter: blur(20px) saturate(1.8);
    backdrop-filter: blur(20px) saturate(1.8);
    background: rgba(60, 65, 66, 0.2901960784);
  }

  .header_flx {
    border: none;
  }
}

.logo-box {
  cursor: pointer;
}

header {
  position: fixed;
  z-index: 1024;
  width: 100%;

  .header_flx {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $c_dark_border;
    padding: 20px 0;
    position: relative;

    .nav_box {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 30px;
      min-width: 20%;
      justify-content: end;

      li {
        color: $c_white;
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .tlg_bx {
      min-width: 20%;

      .menu-toogle {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}

// header csss= end

// footer css start
footer {
  background: $c_black;
  padding: 30px 0 20px;

  a {
    color: $c_white;
    margin-bottom: 20px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    cursor: none;
    width: fit-content;

    &:hover {
      color: $c_white;
      text-decoration: underline !important;
    }
  }

  .logo_head {
    text-align: center;
    border-bottom: 1px solid #383838;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
  }

  .ftr_flx {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;

    .page_link {
      width: 23%;

      p {
        font-size: 14px;
        text-transform: uppercase;
        color: $c_white;
      }

      .download-app {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    .subscribe-box {
      width: 30%;

      input {
        background: #1d1d1d;
        border: none;
        padding: 0 10px;
        color: $c_white;
        font-size: 16px;
        font-weight: 400;
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        min-width: 100px;
        font-size: 14px;
        text-transform: uppercase;
        background: $c_white;
        border: none;
      }

      label {
        font-size: 14px;
        color: $c_white;
        font-weight: 400;
        margin-bottom: 15px;
        text-transform: uppercase;
      }
    }
  }
}

// footer css end

// home banner css start
/* Hero Video */
.outter.hero-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-video {
  .video-container {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: $dark_black;

    @media (max-width: 767px) {
      height: 500px;
    }
  }

  video {
    object-fit: cover; // Set the magic
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @media (max-width: 767px) {
      height: 500px;
    }
  }

  .video-container:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(black, 0.2);
    z-index: 1;
  }

  h1 {
    text-transform: uppercase;
    margin: 0 0 1rem;
    padding: 0;
    line-height: 1.5;
    color: $c_white;
    font-size: 44px;
    font-weight: 300;
  }

  p {
    font-size: 16px;
    color: #fff;
    max-width: 40%;
  }

  .callout {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 10;
    width: 100%;
    margin: auto;

    @media (max-width: 767px) {
      width: 90%;
      padding-top: 80px;
    }
  }
}

// home banner video end
// reserve banner start
.reserve-sec {
  background-image: url("../public/assets/images/reserve_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .rs_hd {
    width: 35%;
    // padding-top: 163px;
    h2 {
      font-size: 30px;
      font-weight: 400;
      text-transform: uppercase;
      color: $c_white;
      margin-bottom: 0;
      line-height: 32px;
      padding-bottom: 22px;
    }
  }
  p.text-paragraph {
    width: 90%;
    margin-bottom: 42px;
    color: #ffffffb2;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
  }
  .slider-container {
    width: 76%;
    height: 70%;
    margin-bottom: 20px;
    .slick-slider {
      height: 100%;
      width: 100%;
      .slick-arrow {
        display: none !important;
      }
      .slick-list {
        height: 100%;
        width: 100%;
        .slick-track {
          height: 100%;
          width: 100%;
        }
        .slick-slide {
          height: 100%;
          width: 100%;
        }
        .slider-box {
        }
      }
    }
    .slider-sec .slick-dots {
      bottom: -45px !important;
    }
    .reserve-desc {
      color: #ffffffb2;
      padding: 0px 20px 20px 26px;
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
    }
    .reserve-heading {
      padding: 20px 20px 20px 26px;
      text-align: left;
      align-items: center;
      display: flex;
      color: #ffffff;
      line-height: 26px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: unset;
    }
    .slider-box figure {
      width: 100%;
      min-height: 100%;
    }
  }
}

// reserve banner start

// service section
.services-sec {
  // padding-bottom: 0;

  .srvc_flx {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    // padding-bottom: 85px;

    .srvc_box {
      width: 44%;

      figure {
        width: 100%;
        height: auto;
        overflow: hidden;

        img {
          @include img_contain(cover);
          transition: $transition;
        }
      }

      .srvc_hdng {
        padding-top: 10px;
        text-align: center;

        h3 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          color: $c_white;
          text-transform: uppercase;
          margin-top: 20px;
        }

        p {
          color: $c_white;
          font-size: 20px;
          padding: 30px 0;
        }
      }

      &:hover img {
        transform: scale(1.1);
        transition: $transition;
      }
    }
  }
  .two {
    justify-content: space-around;
  }
  .paragraph-center {
    width: 60%;
    align-self: center;
    display: flex;
    justify-self: center;
  }
}

//Application Section
.application-sec {
  height: auto;
  background-color: #0f0f0f;
  .left-container {
    height: 119% !important;
    width: 37%;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    span.heading {
      font-size: 26px;
      color: white;
      font-weight: 400;
      line-height: 32px;
      padding-bottom: 22px;
      text-transform: uppercase;
    }
    p.paragraph {
      color: #ffffffb2;
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
    }
    .download-heading {
      padding-top: 58px;
      color: white;
    }
    .download-conatiner {
      display: flex;
      gap: 12px;
      padding-top: 12px;
      figure {
        cursor: pointer;
      }
    }
  }
  .right-container {
    padding-top: 77px;
  }
}

//Infinity Section
.infinity-sec {
  height: 731px;
  background-color: rgb(0 0 0);
  background-image: url("../public/assets/images/infinity.png");
  .conta_iner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .infinity-container {
    width: 55%;
    text-align: center;
    .head {
      display: flex;
      flex-direction: column;
      span.heading {
        font-size: 36px;
        color: white;
        font-weight: 500;
        line-height: 44px;
        text-transform: uppercase;
      }
      span.sub-heading {
        font-size: 24px;
        color: #ffffff;
        font-weight: 300;
        text-align: center;
        line-height: 44px;
        text-transform: uppercase;
      }
      .gradient-header {
        background: linear-gradient(to right, #003fd2, #f3edff);
        box-shadow: 0px 1.77px 5.3px 0px #00000033;
        color: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    p.paragraph {
      color: #ffffffb2;
      font-size: 18px;
      font-weight: 300;
      line-height: 27px;
      padding-top: 12px;
      padding-bottom: 24px;
    }
  }
}

// contact sec start
.contact-sec {
  .contact-circle {
    width: 200px;
    height: 200px;
    border: 1px solid $c_white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px auto 0;
    border-radius: 50%;
    transition: 0.5s all;
    position: relative;
    cursor: pointer;

    p {
      color: $c_white;
      font-size: 20px;
      position: relative;
      z-index: 1;
      font-weight: 300;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 24px;
      background: $c_primary;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      transition: 0.5s all;
    }

    &:hover {
      border-color: transparent;
      transition: 0.5s all;

      &::after {
        width: 200px;
        height: 200px;
        transition: 0.5s all;
        bottom: 0;
        right: 0;
      }
    }
  }
}

// about page css start
.about-sec {
  .ab_lhs {
    .para {
      margin-top: 22px;
    }
  }
  .head {
    display: flex;
    flex-direction: column;
  }
  .heading {
    font-size: 30px;
    color: #ffffff;
    font-weight: 500;
    line-height: 37px;
    text-transform: uppercase;
  }
  span.sub-heading {
    font-size: 20px;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    line-height: 44px;
  }
  .ab_lhs {
    width: 45%;

    h2 {
      margin-bottom: 50px;
    }

    p {
      margin-bottom: 50px;
    }
  }

  .ab_rhs {
    width: 45%;
  }
  .paragraph-center {
    width: 10%;
    align-self: center;
    display: flex;
    justify-self: center;
  }
}
.why_chs {
  .pick-up {
    height: 210px;
  }
}

// about page css end

// why choose sec
.pick-up {
  width: 32%;
  border: 1px solid #292929;
  padding: 30px;

  h3 {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: $c_white;
    // max-width: 50%;
    line-height: 1.5;
  }

  p {
    font-size: 14px;
    color: $c_white;
  }
}
.paragraph-center {
  width: 60%;
  align-self: center;
  display: flex;
  justify-self: center;
}
// why choose sec end

// organisation sec start
.organisation-sec {
  background-image: url("../public/assets/images/organisation.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .sb_hd {
    max-width: 44%;

    p {
      margin: 50px 0;
    }
  }
}

// organisation sec end

// service page css
.exp_fns_box {
  max-width: 661px;
  margin: 0 auto;
}

.carrentel-sec {
  min-height: 100vh;
  padding: 100px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;

  .sb_hd {
    max-width: 40%;
    position: relative;

    h2 {
      font-size: 36px;
      position: relative;
      margin-bottom: 30px;

      // &::after {
      //   content: "";
      //   position: absolute;
      //   background: $c_white;
      //   width: 60px;
      //   height: 3px;
      //   left: 0;
      //   bottom: -5px;
      // }
    }
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 70%;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   background: linear-gradient(to left, #33333300, #000000b8 75%);
  // }

  &.carrentel-sec_ryt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .container {
      width: 100%;
    }
    // &::before {
    //   left: unset;
    //   background: linear-gradient(to left,
    //       rgba(0, 0, 0, 0.7215686275) 75%,
    //       rgb(51 51 51 / 0%));
    //   right: 0;
    // }

    .sb_hd {
      max-width: 394px;
      margin-left: auto;
      text-align: end;

      h2::after {
        left: unset;
        right: 0;
      }
    }
  }
}
.drk-bg-sec {
  min-height: 100vh;
  padding: 100px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      to left,
      rgba(51, 51, 51, 0),
      rgb(0 0 0 / 80%) 75%
    );
  }
  .sb_hd {
    max-width: 40%;
    position: relative;

    h2 {
      font-size: 36px;
      position: relative;
      margin-bottom: 30px;
    }
  }
  &.drk-bg-sec_ryt {
    &::before {
      left: unset;
      // background: linear-gradient(to left,
      //     rgba(0, 0, 0, 0.80) 75%,
      //     rgb(51 51 51 / 0%));
      right: 0;
      background: linear-gradient(
        to right,
        rgba(51, 51, 51, 0),
        rgb(0 0 0 / 80%) 75%
      );
    }

    .sb_hd {
      margin-left: auto;
      text-align: end;

      h2::after {
        left: unset;
        right: 0;
      }
    }
  }
}

// service page css end

//contact page css
.support-lhs {
  width: 40%;

  p {
    margin: 50px 0;
  }
}

.support-rhs {
  width: 40%;

  p {
    margin: 50px 0;
  }
}

.faq-accordion {
  margin-top: 80px;
  padding-bottom: 80px;

  .MuiPaper-elevation1.MuiAccordion-root {
    background: none;
    box-shadow: none;
  }

  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    min-height: 90px;
  }

  .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
    background-color: #383838;
  }

  .MuiAccordionSummary-content {
    color: $c_white;
  }

  .MuiAccordionDetails-root {
    color: $c_white;
    font-size: 14px;
  }

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 30px;
    color: $c_white;
  }
}

.call_sec {
  background: $c_primary;
  padding: 100px 0;
  border-bottom: 8px solid $dark_black;

  .call_box {
    max-width: 350px;
    margin: 0 auto;

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: $c_white;
      margin: 50px 0;
      text-transform: uppercase;
    }

    h4 {
      font-size: 20px;
      font-weight: 500;
      color: $c_white;
      text-transform: uppercase;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: $c_white;
      text-transform: uppercase;
      margin: 20px 0;
    }
  }

  .other_st {
    color: $c_white;
    text-align: center;
    font-size: 14px;
    margin-top: 80px;
  }

  .address_box {
    margin: 40px 0;
  }
}

// slider css
.slider-sec {
  background-color: $dark_black;

  .slider-box {
    figure {
      width: 100%;
      min-height: 100vh;

      img {
        @include img_contain();
      }
    }
  }

  .slick-dots {
    bottom: 60px !important;

    li {
      button {
        border-radius: 50% !important;
        border: 2px solid #828282 !important;
        background: none !important;

        &::before {
          opacity: 1;
          color: #828282 !important;
        }
      }

      &.slick-active {
        button {
          border: 2px solid $c_white !important;

          &::before {
            color: $c_white !important;
          }
        }
      }
    }
  }
}

// sidebar css start
.sidebar-box {
  position: fixed;
  // width: 100%;
  width: 456px;
  max-width: 531px;
  min-height: 100vh;
  top: 0;
  left: -631px;
  transition: 1.5s all;
  z-index: 1025;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 0 72px 0 20px;
  backdrop-filter: blur(20px) saturate(1.8);
  background: #3c41424a;
  height: 100%;
  text-align: right;
  .menuitem {
    height: 22px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }

  li {
    cursor: pointer;
    font-size: 20px;
    text-transform: uppercase;
    color: #ffffff59;
    padding: 20px 0;
    font-weight: 400;
    margin-bottom: 0;
    transform: translate(-530px);

    &:nth-child(2) {
      transition: 0.2s all;
    }

    &:nth-child(3) {
      transition: 0.4s all;
    }

    &:nth-child(4) {
      transition: 0.6s all;
    }

    &:nth-child(5) {
      transition: 0.8s all;
    }

    &:nth-child(6) {
      transition: 1s all;
    }

    &.close_sidebar {
      position: absolute;
      left: 130px;
      top: 80px;
      transform: none;
    }

    &:hover {
      color: $c_white;

      figure {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &.show_sidebar {
    left: 0;
    transition: 1.1s all;

    li {
      transform: translate(0);

      &:nth-child(6) {
        transition: 0.2s all;
      }

      &:nth-child(5) {
        transition: 0.4s all;
      }

      &:nth-child(4) {
        transition: 0.6s all;
      }

      &:nth-child(3) {
        transition: 0.8s all;
      }

      &:nth-child(2) {
        transition: 1s all;
      }
    }
  }
}

// sidebar css end

.cursor__ball--big,
.cursor__ball--small {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1026;
  transform: translate(-50%, -50%);
  /* Center the cursor */
}

.cursor__ball--big,
.cursor__ball--small {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $c_white;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
  transition: width 0.3s, height 0.3s;
}

.cursor__ball--small {
  width: 10px;
  height: 10px;
}

.MuiTabs-scroller .MuiTabs-indicator {
  background-color: #000 !important;
  height: 4px !important;
}

.logo2_mg {
  max-width: 225px;
  // margin-right: 160px;
}
// .logo2_mg.corporate {
//   margin-right: 285px;
// }
.logo2_mg.margin-unset {
  margin-right: unset;
}

body:has(.MuiButtonBase-root:hover) {
  .cursor__ball--big,
  .cursor__ball--small {
    display: none;
  }
}

@media (max-width: 1500px) {
  .mrs_box {
    margin-bottom: 20px;
  }

  .control_group {
    margin-bottom: 15px;
  }

  .login-heading {
    margin: 20px 0 15px;
  }

  .varient-box li,
  .login-page .login_rhs .login-box .or-box {
    margin: 35px 0;
  }

  .login-heading {
    margin: 10px 0 20px;
  }

  .logo2_mg {
    max-width: 180px;
  }
}

@media (max-width: 1200px) {
  .login_rhs {
    padding: 20px;
  }

  .big_cursor svg {
    position: absolute;
    top: 2px !important;
  }

  .login-page::after {
    content: unset;
  }

  .login-page .login_lhs {
    padding-top: 0;
    padding-left: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0;
  }

  .varient-box li {
    margin: 20px 0;
  }

  .varient-box li h2 {
    margin-bottom: 5px;
  }

  .tab-area .MuiTabs-flexContainer button {
    font-size: 18px;
    padding: 10px 60px;
  }

  .login-page .login_rhs .login-box {
    max-width: unset;
    margin: 0 auto;
  }

  .login-heading {
    margin: 0;
  }

  .login-page .login_rhs .login-box .or-box {
    margin: 20px 0;
  }

  .login-heading h2 {
    font-size: 20px;
  }

  .varient-box li h2 {
    font-size: 16px;
  }

  .mrs_box {
    gap: 10px;
  }

  .login-page {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .login-page .gap_m {
    width: 100%;
  }

  .upload_proof {
    margin-top: 30px;
  }

  .modal-main .css-1wnsr1i {
    padding: 20px 40px !important;
    width: 60% !important;
  }

  .modal-main h2 {
    font-size: 20px;
  }

  .modal-main .crs_icn {
    width: 24px;
    height: 24px;
    top: 10px;
    right: 10px;
  }

  .bg_dark {
    padding: 40px 0;
  }

  .dark_bg {
    padding: 40px 30px 50px;
  }

  .contact-sec .contact-circle p {
    font-size: 14px;
  }

  .contact-sec .contact-circle {
    width: 150px;
    height: 150px;
    margin: 30px auto 0;
  }

  .services-sec .srvc_flx {
    margin-top: 30px;
  }

  // .services-sec .srvc_flx .srvc_box figure {
  //   height: 380px;
  // }

  .services-sec .srvc_flx .srvc_box .srvc_hdng {
    padding-top: 10px;
  }

  .services-sec .srvc_flx .srvc_box .srvc_hdng h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .hero-video h1,
  .reserve-sec .rs_hd h2 {
    font-size: 30px;
  }

  .reserve-sec .rs_hd h2,
  .about-sec .ab_lhs p,
  .about-sec .ab_lhs h2 {
    margin-bottom: 20px;
  }

  .about-sec .ab_rhs {
    display: none;
  }

  .about-sec .ab_lhs,
  .organisation-sec .sb_hd,
  .carrentel-sec .sb_hd {
    width: 100%;
    text-align: center;
  }

  .organisation-sec .sb_hd,
  .hero-video p,
  .pick-up h3,
  .carrentel-sec .sb_hd {
    max-width: 100%;
  }

  .hero-video p {
    max-width: 533px;
  }

  .organisation-sec .sb_hd p {
    margin: 10px 0 20px;
  }

  .organisation-sec {
    min-height: unset;
    background-position: top;
  }

  .slider-sec .slider-box figure {
    min-height: unset;
  }

  .pick-up {
    padding: 20px;
    text-align: center;
  }

  .mb_50 {
    margin-bottom: 30px;
  }

  .support-lhs p,
  .support-rhs p {
    margin: 4px 0 10px;
    line-height: 22px;
  }

  .call_sec {
    padding: 40px 0;
  }

  .call_sec .call_box h3 {
    margin: 15px 0;
  }

  .call_sec .call_box h5 {
    margin: 0 0 5px;
    font-size: 14px;
  }

  .call_sec .call_box h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .call_sec .call_box h3 {
    margin: 15px 0 5px;
    font-size: 16px;
  }

  .call_sec .other_st {
    margin: 15px 15px 0;
  }

  .call_sec .address_box {
    margin: 15px 0;
  }

  .faq-accordion {
    margin-top: 30px;
  }

  .Mui-expanded {
    margin: 16px 0 0;
  }

  .faq-accordion .MuiAccordionDetails-root {
    padding: 10px;
  }

  .faq-accordion .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    min-height: unset !important;
  }

  .faq-accordion .MuiAccordionSummary-content {
    margin: 10px 0;
    line-height: normal;
    font-size: 16px;
  }

  .carrentel-sec .sb_hd h2::after,
  .carrentel-sec.carrentel-sec_ryt .sb_hd h2::after {
    left: 0;
    right: 0;
    margin: auto;
  }

  .carrentel-sec.carrentel-sec_ryt .sb_hd {
    text-align: center;
    margin: auto;
  }

  .carrentel-sec .sb_hd h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .carrentel-sec {
    position: relative;
    min-height: 22vh;
    padding: 80px;
  }
  .why-section .tble p.top-heading {
    width: 50% !important;
  }
  .application-sec .left-container {
    padding-bottom: 40px;
  }

  .carrentel-sec:after {
    position: absolute;
    content: "";
    background: #000;
    opacity: 0.4;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  .carrentel-sec .sb_hd {
    z-index: 1;
  }

  .sidebar-box li {
    font-size: 16px;
    padding: 0;
    margin-bottom: 20px;
  }

  .logo2_mg {
    max-width: 140px;
  }
  .about-sec .heading,.sub-heading,.para,p {
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .varient-box,
  .login-page::before {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 0 0 80px;
  }
  .varient-box {
    .sub-heading {
      font-size: 18px !important;
    }
    .vendor li {
      margin: 0 !important;
    }
    li {
      margin: 20px 0 !important;
    }
  }

  .logo2_mg {
    max-width: 110px;
  }

  .login-page .login_lhs img {
    filter: unset;
  }

  .login-page .login_lhs {
    align-items: center;
    padding-left: 0;
    margin: 0;
    height: 100vh;
    background: black;
    width: 100% !important;
  }

  .login-page .gap_m {
    width: 100%;
    flex-direction: column;
    margin: 0;
    height: auto;
    min-height: auto;
  }

  .login_rhs.w_50 {
    width: 100% !important;
    margin: 0;
    height: 100vh;
    min-height: 100vh;
  }
  span.learn-more {
    display: unset;
  }
  .vendor-container-full-width {
    .grid-group {
      padding-left: 24px;
      padding-right: 24px;
    }
    .heading {
      margin-right: unset !important
    }
    .tble p.top-heading {
      width: 100% !important;
    }
  }

  .login-page .tab-area {
    margin-top: 0;
  }

  .login-page {
    overflow: hidden;
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnn,
  button.MuiButton-root {
    min-height: 50px;
  }

  .mrs_box li {
    font-size: 14px;
  }

  .control_group {
    margin-bottom: 10px;
  }

  input,
  input.MuiInputBase-input,
  .form .react-tel-input .form-control {
    height: 45px;
    font-size: 14px;
  }

  .rs_hd {
    text-align: left;
    width: 71% !important;
  }
  .application-sec .right-container {
    padding-top: 102px !important;
  }

  .application-sec .left-container {
    height: 100% !important;
    width: 37%;
    display: flex;
    flex-direction: column;
    padding-top: 44px;
    padding-bottom: 30px;
  }
  .infinity-first-sec .top-left {
    margin-top: 50px !important;
  }
  .infinity-fourth-sec .top-left span.sub-heading {
    font-size: 26px !important;
  }
  .infinity-sec .infinity-container .head span.heading {
    font-size: 32px !important;
  }

  .services-sec .srvc_flx .srvc_box {
    width: 48%;
    position: relative;
  }

  .services-sec .srvc_flx .srvc_box:after {
    position: absolute;
    content: "";
    background: #000;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  .services-sec .srvc_flx {
    justify-content: center;
    gap: 15px;
  }

  .services-sec .srvc_flx .srvc_box .srvc_hdng {
    position: absolute;
    inset: 0;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 0;
    z-index: 1;
  }

  footer a {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .pick-up {
    width: 48%;
  }

  .why_chs .d_flx {
    justify-content: center;
    gap: 15px;
  }

  .support-lhs,
  .support-rhs {
    width: 100%;
  }

  .sprt_sc .d_flx {
    gap: 25px;
  }
  .paragraph-center {
    width: 60%;
    align-self: center;
    display: flex;
    justify-self: center;
  }
  .big_cursor svg {
    position: absolute;
    top: 2px !important;
  }
  .contact-faq-sec {
    span.sub-heading{
      font-size: 14px;
    }
    .sb_hd {
      width: 50%;
      .form-section {
        width: 100%;
        padding-right: 24px;
      }
    }
  }
}

@media (max-width: 767px) {
  .varient-box,
  .login-page::before {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 10px;
  }
  .varient-box {
    .sub-heading {
      font-size: 18px !important;
    }
    .vendor li {
      margin: 0 !important;
    }
    li {
      margin: 20px 0 !important;
    }
  }
  .login-page .login_lhs img {
    filter: unset;
  }
  .login-page .login_lhs {
    align-items: center;
    padding-left: 0;
    margin: 0;
    height: 100vh;
    background: black;
    width: 100% !important;
  }
  .login-page .gap_m {
    width: 100%;
    flex-direction: column;
    margin: 0;
    height: auto;
    min-height: auto;
  }

  .login_rhs.w_50 {
    width: 100% !important;
    margin: 0;
    height: 100vh;
    min-height: 100vh;
  }
  .login-page .tab-area {
    margin-top: 0;
  }

  .login-page {
    overflow: hidden;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .varient-box li h2 {
    font-size: 14px;
  }
  .varient-box li p {
    font-size: 14px;
  }
  .varient-box li figure {
    margin-top: 4px;
  }
  figure.scnd,figure.first {
    width: unset !important;
  }
  span.learn-more {
    display: none;
  }
  figure.thrd {
    width: 22px !important;
  }
  .sidebar-box li {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .sidebar-box .menuitem {
    height: 22px;
    font-size: 12px;
  }

  .reserve-sec {
    min-height: unset;
    padding: 100px 0;
    .rs_hd {
      width: auto;
    }
    .slider-container {
      width: 100%;
      height: auto;
      text-align: left;
    }
  }

  //Application Section
  .application-sec {
    .conta_iner {
      flex-direction: column;
    }
    .left-container {
      height: auto !important;
      width: 100%;
      padding: 60px 0;
    }
    .right-container {
      padding-top: 0;
    }
  }

  //Infinity Section
  .infinity-sec {
    height: auto;
    min-height: auto;
    .infinity-container {
      width: 100%;
      text-align: center;
    }
  }

  .vendor-first-sec .top {
    height: auto !important;
  }

  .vendor-sec-sec {
    height: auto !important;
    .flx {
      flex-direction: column !important;
      .bg-mobile-left {
        width: 100%;
      }
      .right-sec-scrl {
        width: 100% !important;
      }
    }
  }

  footer .ftr_flx .page_link {
    width: 100%;
    margin-bottom: 20px;
  }

  footer .ftr_flx .page_link:last-child {
    margin-bottom: 0;
  }

  footer a {
    margin-bottom: 5px;
  }

  footer .ftr_flx .subscribe-box {
    width: 100%;
  }

  footer .ftr_flx .subscribe-box label {
    margin-bottom: 10px;
  }

  header .header_flx .nav_box li {
    font-size: 12px;
  }

  .lang-select {
    gap: 5px;
  }

  header .header_flx .nav_box {
    gap: 15px;
  }

  .logo-box {
    max-width: 65px;
  }

  .sidebar-box.show_sidebar {
    padding: 0 24px;
    width: 200px;
    .big_cursor svg {
      position: absolute;
      top: 2px;
    }
  }

  .sidebar-box li.close_sidebar {
    left: 20px;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .modal-main .css-1wnsr1i {
    width: 90% !important;
  }

  .modal-main .modal-btn {
    margin-top: 25px;
  }

  .services-sec .srvc_flx .srvc_box,
  .pick-up {
    width: 100%;
  }

  .hero-video h1,
  .reserve-sec .rs_hd h2 {
    font-size: 26px;
  }

  .pick-up h3,
  .hero-video h1,
  .about-sec .ab_lhs h2,
  .sb_hd h2 {
    margin-bottom: 5px;
  }

  .carrentel-sec .sb_hd h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .btnn.btn_white {
    font-size: 14px;
  }

  .carrentel-sec {
    min-height: unset;
    padding: 40px 0;
  }

  .hero-video video,
  .hero-video .video-container {
    height: 400px;
  }

  .tab-area .MuiTabs-flexContainer button {
    font-size: 14px;
    padding: 10px 40px;
  }

  .login_rhs {
    padding: 20px 0;
  }

  .btnn,
  button.MuiButton-root {
    font-size: 14px;
    padding: 5px 20px;
  }

  .login-page .login_rhs .login-box .MuiFormControl-root label {
    width: 100%;
  }

  .login-page
    .login_rhs
    .login-box
    .MuiFormControl-root
    label
    .MuiFormControlLabel-label {
    font-size: 14px;
    margin-left: 5px;
  }
}

@media (max-width: 480px) {
  .hero-video h1,
  .reserve-sec .rs_hd h2,
  .sb_hd h2,
  .carrentel-sec .sb_hd h2 {
    font-size: 20px !important;
    font-weight: 400;
  }
  .hero-video p {
    font-size: 12px !important;
    padding: unset !important;
  }
  .hero-video.service-page {
    p {
      max-width: 100%;
    }
  }
  .btnn.btn_white {
    font-size: 14px;
    min-height: 42px;
    min-width: 100px;
  }
  .flx-center p.center-paragraph {
    width: 100% !important;
  }
  .services-sec .conta_iner .sb_hd.flx-center {
    align-items: flex-start !important;
  }
  .services-sec .conta_iner .flx-center p.center-paragraph {
    text-align: left !important;
  }
  .flx-center span.sub-heading {
    text-align: left !important;
  }
}

.show_sidebar.otsd_clcl {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1023;
}

body {
  &:has(.big_cursor:hover) {
    .cursor__ball--big {
      width: 30px;
      height: 30px;
    }
  }
}

.privacy-page {
  padding-bottom: 5px;
  .conta_iner {
    max-width: 800px;
  }

  p,
  li {
    font-size: 14px;
    margin-bottom: 40px;
    color: $c_white;
  }

  * {
    color: $c_white;
  }

  .get-some {
    margin-top: 100px;
  }
  .paragraph-center {
    width: 70%;
    align-self: center;
    display: flex;
    justify-self: center;
  }
}

body {
  &:has(.login-page:hover) {
    .cursor__ball--big {
      border-color: #000;
    }
  }

  &:has(.login-page .login_lhs:hover) {
    .cursor__ball--big {
      border-color: #fff;
    }
  }
}

.css-1fx8m19 {
  color: rgb(255, 255, 255) !important;
}

.otp-input-container > div {
  justify-content: center;
}

.otp_vw h3 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 50px;
}

.otp_vw {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.otp_vw .form_btn {
  margin-top: 35px;
  width: 100%;
}

.modal-main p {
  text-align: center;
  line-height: 24px;
  margin: 10px 0 0;
  font-size: 16px;
}

.control_group {
  p {
    margin: 0 !important;
  }
}

.date_flx {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .control_group {
    width: 48%;
  }
}

.proof-flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.error-message {
  color: red;
  font-size: 0.675rem;
  /* Small font size */
  margin-top: 4px;
  /* Small space above the error message */
}

body:has(.MuiInputBase-input:hover) {
  .cursor__ball--big,
  .cursor__ball--small {
    display: none;
  }
}
/* Styles for mobile */
@media screen and (max-width: 1200px) {
  .mobile-res-only {
    display: flex;
    opacity: 1;
    height: 30%;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    .logo2_mg {
      margin-right: unset;
    }
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 750px;
      padding: 46px 20px 0px 20px;
      .heading {
        color: white;
        font-size: 26px;
        font-weight: 400;
        line-height: 64px;
        text-align: center;
      }
      span.sub-heading {
        color: white;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        text-align: center;
      }
      p.paragraph {
        color: #ffffff99;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        text-align: center;
        padding-top: 10px;
      }
    }
  }
  .login_rhs {
    display: none;
    opacity: 0;
  }
  .login_lhs {
    width: 100% !important;
  }
  .vendor-container-full-width {
    display: none;
    opacity: 0;
  }
  .varient-box {
    display: none;
    opacity: 0;
  }
  .login-page::before {
    width: 100%;
  }
  .login-ftr-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .logo2_mg {
    display: flex;
    justify-content: center;
    margin: auto;
  }

}
@media screen and (min-width: 1201px) {
  .mobile-res-only {
    display: none;
    opacity: 0;
  }
  .login_rhs {
    display: block;
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .cursor__ball--big,
  .cursor__ball--small {
    display: none;
  }

  body:has(.show_sidebar.sidebar-box),
  html:has(.show_sidebar.sidebar-box) {
    overflow: hidden;
  }

  .login-heading {
    margin: 0 0 20px;
  }

  .login-heading p {
    font-size: 14px;
  }

  .login-page {
    padding: unset;
    align-items: flex-start;
    height: auto;
    min-height: auto;
    overflow: auto;
  }

  .crs_icn {
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
  }
}
.nodata {
  margin: 0 auto;
  display: block;
}
.custom_tabs {
  button {
    color: #ffffff;
    text-transform: none;
  }

  .MuiTabs-flexContainer {
    max-width: fit-content;
    margin: 0 auto 40px;
    border-radius: 55px;
    border: 1px solid #fff;
    padding: 2px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .Mui-selected {
    color: #000000 !important;
    background: #ffffff;
    border-radius: 30px;
  }

  .MuiTabs-scroller .MuiTabs-indicator {
    display: none;
  }
}

.loading {
  img {
    filter: blur(5px);
  }
}
.service-second-heading {
  width: 80%;
  margin: auto;
  padding-bottom: 12px;
}
.service-tb {
  padding: 80px 80px 0px;
  background-color: #0f0f0f;
}
.why-section {
  padding: 80px 80px 0px;
  color: white;
  background-color: #000000;
  height: 567px;
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    flex-direction: column;
    text-align: center;
    span.heading {
      font-size: 30px;
      color: #ffffff;
      font-weight: 500;
      line-height: 37px;
      text-transform: uppercase;
    }
    span.sub-heading {
      font-size: 20px;
      color: #ffffff;
      font-weight: 400;
      text-align: center;
      line-height: 44px;
    }
  }
  p.paragraph {
    color: #ffffffb2;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    padding-top: 12px;
    padding-bottom: 24px;
    width: 84%;
    text-align: center;
    margin: auto;
  }
  .tble {
    height: 146px;
    width: 84%;
    margin: auto;
    border: 1px solid #222222;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    position: relative;
    .first,
    .second {
      margin: 42px 10px;
      border-right: 1px solid #222222;
    }
    .third {
      margin: 42px 10px;
    }
    .first,
    .second,
    .third {
      width: 33.3%;
      display: flex;
      justify-content: center;
      p {
        font-size: 18px;
        color: white;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        width: 79%;
      }
    }
    p.top-heading {
      position: absolute;
      top: -20px;
      color: white;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      background: black;
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
    }
  }
  .download-conatiner {
    width: 30%;
    flex-direction: row;
    margin: auto;
    gap: 12px;
    padding-top: 40px;
  }
}
.why-section.app-link {
  height: 401px !important;
  p.paragraph {
    width: 64%;
  }
}
.corporate {
  .tble .first,
  .second,
  .third {
    width: 33.3%;
    display: flex;
    justify-content: center;
    p {
      font-size: 18px;
      color: white;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      width: 90% !important;
    }
  }
  p.top-heading {
    width: 52% !important;
  }
}
.infinity-first-sec {
  height: 768px;
  display: flex;
  background-color: rgb(0 0 0);
  background-image: url("../public/assets/images/infinity-page-1.png");
  .conta_iner {
    display: flex;
  }
  .top-left {
    height: 415px;
    width: 53%;
    margin-top: 132px;
    display: flex;
    // background: radial-gradient(39.31% 37.31% at 31% 63.87%, rgba(117, 117, 117, 0.02) 0%, rgba(0, 0, 0, 0) 100%);
    background: radial-gradient(
      40.31% 51.31% at 40% 47.87%,
      rgba(117, 117, 117, 0.02) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    -webkit-backdrop-filter: blur(304px);
    backdrop-filter: blur(321px);
  }
  .top-right {
    height: 395px;
    width: 33%;
    margin-top: 195px;
    margin-left: 152px;
  }
  .infinity-container {
    margin-top: 147px;
    height: 263px;
    width: 73%;
    .head {
      display: flex;
      flex-direction: column;
      span.heading {
        font-size: 24px;
        color: white;
        font-weight: 300;
        line-height: 54px;
        text-transform: uppercase;
      }
      span.sub-heading {
        font-size: 36px;
        color: #ffffff;
        font-weight: 300;
        text-align: left;
        line-height: 52px;
        text-transform: uppercase;
      }
      .gradient-header {
        background: linear-gradient(to right, #003fd2, #f3edff);
        box-shadow: 0px 1.77px 5.3px 0px #00000033;
        color: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    p.paragraph {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff99;
      line-height: 26px;
      width: 90%;
    }
  }
}
.second-sec .infinity-container {
  width: 71% !important;
  position: relative;
}
.just-bg-shadow {
  height: 386px;
  left: -101px;
  width: 239px;
  transform: rotate(45deg);
  margin-top: -197px;
  position: absolute;
  background: radial-gradient(
    60% 50% at 48% 48%,
    rgba(0, 63, 210, 0.16) 0%,
    rgba(29, 29, 29, 0) 100%
  );
}
.infinity-sec.third-sec {
  display: flex;
  background-image: none;
  background-color: #0f0e0e;
  .infinity-container {
    width: 92% !important;
  }
  .grid-group {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 63, 210, 0.1) 0%,
      rgba(29, 29, 29, 0) 100%
    );
    backdrop-filter: blur(254px);
    padding-top: 62px;
    .grid {
      display: flex;
      .first,
      .second,
      .third,
      .fourth {
        padding: 46px 46px 20px 46px;
        span.sub-heading {
          font-size: 18px;
          color: #ffffff;
          font-weight: 400;
          text-align: left;
          line-height: 44px;
          text-transform: uppercase;
        }
        p.paragraph {
          padding-bottom: 0px;
        }
        width: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
      }
      .first {
        border-right: 1px solid #434d66;
        border-bottom: 1px solid #434d66;
      }
      .second {
        border-bottom: 1px solid #434d66;
      }
      .third {
        border-right: 1px solid #434d66;
      }
    }
  }
}
.infinity-fourth-sec {
  height: 712px;
  display: flex;
  background-color: rgb(0 0 0);
  background-image: url("../public/assets/images/infinity-hand.png");
  .top-left {
    width: 48%;
    span.sub-heading {
      font-size: 30px;
      color: white;
      font-weight: 400;
      line-height: 106px;
      box-shadow: 0px 1.77px 5.3px 0px #00000033;
      text-transform: uppercase;
    }
    p.paragraph {
      font-size: 16px;
      line-height: 32px;
      font-weight: 300;
      color: #ffffffb2;
    }
    .form-section {
      display: flex;
      flex-direction: column;
      padding-bottom: 44px;
      input.form-control {
        padding-top: 26px;
        height: 72px;
        z-index: 2;
        border-color: #3b3b3b;
        color: #ffffffb2;
        padding-left: 10px;
        &::placeholder {
          color: #ffffffb2;
          opacity: 1;
        }
      }
      span.sub-heading {
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        color: white;
        z-index: 2;
        padding-top: 38px;
        padding-bottom: 12px;
      }
      .single-rw {
        display: flex;
        gap: 10px;
      }
      .w50 {
        width: 50%;
      }
    }
    .btnn.btn_white {
      min-height: 46px;
      min-width: 150px;
    }
  }
}
@media (max-width: 430px) and (max-height: 933px) and (not (max-width: 375px)) {
  .service-tb {
    position: relative !important;
  }
  .service-tb .carrentel-sec {
    position: absolute !important;
    top: 0px !important;
    padding: 20px !important;
  }
  .service-tb .carrentel-sec::after {
    background: none !important;
  }
}
@media (max-width: 480px) {
  .infinity-first-sec {
    height: 768px;
    .conta_iner {
      flex-direction: column;
    }
    .top-left {
      height: 50%;
      width: 100%;
      margin-top: 32px;
    }
    .top-right {
      height: 50%;
      width: 50%;
      margin: auto;
      margin-top: 162px;
    }
  }
  .infinity-sec .infinity-container {
    width: 100% !important;
    text-align: left;
  }
  .infinity-sec .infinity-container .head span.sub-heading {
    text-align: left;
  }
  .infinity-sec.third-sec .infinity-container {
    width: 100% !important;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 40px;
    .grid {
      flex-direction: column;
      .first,.second,.third,.fourth {
        width: 100%;
        border: none;
        padding: 26px 26px 10px 26px;
      }
    }
  }
  .infinity-fourth-sec {
    .conta_iner {
      height: 100%;
      .top-left {
        width: 100%;
        span.sub-heading {
          font-size: 24px;
        }
      }
    }
  }
  .infinity-fourth-sec.drk-bg-sec {
    padding: 0;
  }
  .application-sec.vendor-frth .right-container figure img {
    height: 74% !important;
  }
  .service-tb {
    padding: 20px 0;
    .conta_iner {
      padding: unset;
      text-align: left;
    }
  }
  .why-section.corporate {
    padding: 50px 20px 50px 20px;
    .conta_iner {
      margin: unset;
      width: 100%;
      .head {
        text-align: left;
        .heading {
          font-size: 20px;
        }
        .sub-heading {
          font-size: 16px;
          text-align: left;
        }
      }
      .paragraph {
        font-size: 14px;
        text-align: left;
      }
      
    }
  }
  #individual .carrentel-sec, #corp .carrentel-sec {
    min-height: auto;
    padding: 20px 20px !important;
  }
  .carrentel-sec {
    .text-white h2 {
      text-align: left;
    }
  }
  .service-second-heading {
    margin: unset;
  }
  .table .first,
  .second,
  .third {
    p {
      text-align: left !important;
    }
  }
  .vendor-third-sec .tble .first p{
    text-align: left !important;
  }
  .vendor-third-sec .top {
    align-items: flex-start !important;
  }
  .why-section {
    padding: 20px;
    .conta_iner {
      margin: unset;
      width: 100%;
    }
    .head span.heading {
      font-size: 20px !important;
      line-height: 32px;
      text-align: left;
    }
    .head span.sub-heading {
      font-size: 16px;
      line-height: 32px;
      text-align: left;
    }
    p.paragraph {
      font-size: 14px;
      text-align: left;
    }
  }
  .tble {
    height: 186px;
    p.top-heading {
      font-size: 16px;
      font-weight: 400;
    }
    .first,.second,.third,.top-heading {
      margin: unset !important;
      padding: 0 20px;
    }
    .first {
      p {
        margin-top: 20px;
      }
    }
    .first,.second,.third {
      p {
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 20px;
      }
    }
    .top-heading {
      font-size: 16px !important;
      font-weight: 300 !important;
    }
  }
  .why-section.app-link {
    padding: 40px 20px;
    .conta_iner {
      width: 100%;
      margin: unset;
      .head {
        text-align: center;
      }
      p.paragraph {
        width: 100%;
      }
    }
  }
  .carrentel-sec {
    padding: 60px 20px !important;
  }
  .service-page p {
    font-size: 10px !important;
  }
  .reserve-sec {
    background-image: url("../public/assets/images/reserve_bg-mobile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    min-height: 100vh;
  }
  #individual {
    background-image: url("../public/assets/images/self-drive-rental-mobile.png") !important;
    background-size: contain;
  }
  .self-drive-rental-service {
    background-image: url("../public/assets/images/indi-self-drive-rantal-right-mobile.png") !important;
  }
  .chauffeur-drive-rental-service {
    background-image: url("../public/assets/images/chauffeur-rental-service-mobile.png") !important;
  }
  #corp {
    background-image: url("../public/assets/images/chauffeur-rental-left-mobile.png") !important;
  }
  #corp,.service-tb {
    background-size: contain;
  }
  .why-section .tble p {
    top: -62px;
    text-align: left !important;
    padding: 0px;
    margin: unset !important;
    justify-content: left !important;
    color: #a5a5a5 !important;
    font-size: 14px !important;
  }
  .vendor-first-sec {
    background-position: center bottom;
    padding-top: 80px;
    background-image: url("../public/assets/images/vendor-landing-bg-mobile.png") !important;
    min-height: 100vh !important;
  }
  .dark_bg.privacy-page {
    padding: 20px 20px 50px;
    .text_center {
      text-align: left;
      .paragraph-center {
        width: 100% !important;
      }
    }
  }
  input:focus {
    transform: scale(1);
  }
  .contact-faq-sec {
    padding: 20px !important;
    .sub-heading {
      width: 100% !important;
      font-size: 14px !important;
    }
    .sb_hd {
      width: 100% !important;
      .form-section {
        width: 100% !important;
        .form-control {
          width: 94% !important;
        }
      }
    }

  }
}
.big_cursor {
  position: relative;
  svg {
    position: absolute;
    top: 26px;
  }
}
.w55 {
  max-width: 538px !important;
}

.flx-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span.sub-heading {
    font-size: 18px;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    line-height: 32px;
    text-transform: uppercase;
  }
  p.center-paragraph {
    width: 60%;
    color: rgba(255, 255, 255, 0.6980392157);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 4px;
    padding-bottom: 24px;
  }
}
.login_rhs.w_50 {
  width: 50%;
}
.login_lhs.full-width {
  width: 100%;
  transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1),
    opacity 0.8s ease-in-out;
  transform: translateX(0%);
}
.login_lhs.w_50 {
  width: 50%;
}
.login_lhs {
  position: relative;
}
figure.first {
  width: 37px;
}
figure.scnd {
  width: 32px;
}
figure.thrd {
  width: 29px;
}
.pl-20 {
  padding-left: 20px;
}
span.learn-more {
  color: #2f61bc;
  padding-left: 10px;
  background-image: url("../public/assets/images/arrow-right.svg");
}
.contact-faq-sec {
  background-color: #0e0f0f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  textarea {
    padding: 10px 0px !important;
  }
  .frm-container {
    margin-bottom: 50px;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: black;
    padding-bottom: 20px;
  }
  span.sub-heading {
    width: 600px;
    font-size: 25px;
    color: #ffffff;
    display: flex;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    margin: auto;
    padding-bottom: 30px;
  }
  .form-section {
    width: 450px;
    text-align: center;
    margin: auto;
    .form-control {
      margin: 10px;
      border-color: #3b3b3b;
      color: #ffffffb2;
      &::placeholder {
        color: #ffffffb2;
        opacity: 1;
      }
    }
  }
}
.vendor-container-full-width {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  height: 60vh;
  overflow: auto;
  .scrl-container {
    height: 60vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .crs_icn {
    background-color: white;
    margin-top: 0px;
    margin-right: 12px;
  }
  .logo2_mg {
    margin-right: unset;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 46px;
    width: 750px;
    padding-bottom: 20px;
    max-width: 750px;
    .heading {
      color: white;
      font-size: 26px;
      font-weight: 400;
      line-height: 64px;
      text-align: center;
    }
    span.sub-heading {
      color: white;
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
    }
    p.paragraph {
      color: #ffffff99;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      padding-top: 10px;
    }
  }
  .tble {
    height: 146px;
    // width: 100%;
    width: 1284px;
    margin: auto;
    // border: 1px solid #222222;
    display: flex;
    justify-content: space-between;
    margin-top: 72px;
    margin-bottom: 36px;
    position: relative;
    .first,
    .second {
      margin: 42px 10px;
      border-right: 1px solid #222222;
    }
    .third {
      margin: 42px 10px;
    }
    .first,
    .second,
    .third {
      width: 33.3%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 18px;
        color: #ffffff99;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        width: 79%;
      }
    }
    p.top-heading {
      position: absolute;
      top: -20px;
      color: white;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      background: black;
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
    }
  }
  .head .heading {
    color: white;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-right: 24px;
  }
  .grid-group {
    padding-top: 52px;
    max-width: 790px;
    .grid {
      display: flex;

      .first,
      .second,
      .third,
      .fourth {
        padding: 2px 46px 20px 46px;
        span.sub-heading {
          font-size: 18px;
          color: #ffffff;
          font-weight: 400;
          text-align: left;
          line-height: 44px;
          text-transform: uppercase;
        }
        p.paragraph {
          color: #ffffff99;
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
          text-align: center;
          padding-top: 10px;
        }
        width: 50%;
        display: flex;
        flex-direction: column;
        text-align: left;
      }
      .third,
      .fourth {
        padding: 24px 46px 20px 46px;
      }
      .first {
        border-right: 1px solid #434d66;
        border-bottom: 1px solid #434d66;
      }
      .second {
        border-bottom: 1px solid #434d66;
      }
      .third {
        border-right: 1px solid #434d66;
      }
    }
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .vendor-container-full-width .tble {
    width: 768px;
  }

  .services-sec .conta_iner .sb_hd.flx-center {
    align-items: flex-start !important;
  }
  .services-sec .conta_iner .flx-center p.center-paragraph {
    text-align: left !important;
  }
}
.login-page.full-width .logo2_mg {
  margin-bottom: 20px;
}
.vendor-first-sec {
  background-image: url("../public/assets/images/vendor-landing-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  background-color: #000;
  width: 100%;
  padding-top: 80px;
  flex-direction: column;
  align-items: center;
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20% 0;
    // width: 750px;
    // max-width: 750px;
    height: 50vh;
    .heading {
      color: white;
      font-size: 42px;
      font-weight: 400;
      line-height: 64px;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    span.sub-heading {
      color: #ffffff99;
      font-size: 24px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      padding-bottom: 20px;
    }
    span.light-sbheading {
      color: white;
    }
    p.paragraph {
      color: #ffffff99;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      padding-top: 10px;
    }
  }
  .bg-car {
    // background-image: url(../public/assets/images/vendor-landing-new.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 50vh;
    display: block;
    margin-top: 16px;
    position: relative;
  }
}
.vendor-sec-sec {
  height: 100vh;
  width: 100%;
  background-color: rgb(0 0 0);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    // width: 750px;
    // max-width: 750px;
    .white-dot-circle {
      height: 8px;
      width: 8px;
      background: white;
      border-radius: 50%;
      margin: 12px;
    }
    .heading {
      color: white;
      font-size: 36px;
      font-weight: 300;
      line-height: 64px;
      text-align: center;
      text-transform: uppercase;
    }
    span.sub-heading {
      color: white;
      font-size: 24px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
    }
    p.paragraph {
      color: #ffffff99;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      padding-top: 10px;
    }
  }
  .flx {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    .bg-mobile-left {
      width: 49%;
      // height: 90%;
      // margin-top: 36px;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      figure {
        height: 100%;
        // width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        // img {
        //   height: 100%;
        //   width: 86%;
        // }
      }
    }
    .right-sec-scrl {
      height: 62vh;
      width: 51%;
      display: flex;
      overflow-x: auto;
      margin-left: -10px;
      // margin-top: 36px;
      flex-direction: column;
      border-left: 1px solid #4c4a4ae5;
      .box {
        // height: 157px;
        // width: 596px;
        padding: 30px 0px;
        padding-left: 24px;
        border-bottom: 1px solid #4c4a4ae5;
        span.heading {
          font-size: 26px;
          font-weight: 500;
          line-height: 30px;
          color: #ffffffb2;
        }
        p.paragraph {
          padding: 24px 20px 0;
          font-size: 18px;
          font-weight: 300;
          line-height: 24px;
          color: #ffffffcc;
        }
      }
      .box:last-child {
        border-bottom: none;
      }
    }
  }
}
.vendor-sec-sec.txt {
  height: 150px;
}
.vendor-third-sec {
  height: 520px;
  width: 100%;
  background-color: #0f0f0f;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .conta_iner {
    height: 57%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bg-shade-btm {
    background: linear-gradient(
      to bottom,
      rgba(51, 51, 51, 0),
      rgb(0 0 0 / 54%) 75%
    );
    width: 100%;
    height: 167px;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 80px;
    // width: 750px;
    // max-width: 750px;
    margin: auto;
    span.heading {
      font-size: 36px;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
      line-height: 64px;
      text-align: center;
    }
    span.sub-heading {
      font-size: 24px;
      font-weight: 300;
      color: white;
      line-height: 26px;
      text-align: center;
    }
  }
  .tble {
    height: 146px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    // margin-top: 72px;
    position: relative;
    .first,
    .second {
      margin: 42px 10px;
      border-right: 1px solid #222222;
    }
    .third {
      margin: 42px 10px;
    }
    .first,
    .second,
    .third {
      width: 33.3%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 18px;
        color: #ffffff99;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        width: 86%;
      }
    }
    p.top-heading {
      position: absolute;
      top: -20px;
      color: white;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      background: black;
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
    }
  }
}
.application-sec.vendor-frth {
  background-image: url("../public/assets/images/vendor-footer-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-color: black;
  // min-height: 100vh;
  .conta_iner {
    width: 80%;
    // background: radial-gradient(49% 51% at 49% 51%, rgba(217, 217, 217, 0.1) 0%, rgba(2, 2, 2, 0) 100%);
  }
  .right-container {
    padding: unset;
    margin-top: 30px;
    figure {
      height: 490px;
      img {
        height: 100%;
      }
    }
  }
}
/* Works in Chrome, Edge, Safari */
::-webkit-scrollbar {
  width: 10px; /* Width of scrollbar */
}

/* Background of the scrollbar track */
::-webkit-scrollbar-track {
  background: #121313; /* Change this to your desired background color */
  border-radius: 5px;
}

/* Scrollbar handle (thumb) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(
    45deg,
    #000000,
    #000000
  ); /* Gradient or solid color */
  border-radius: 5px;
  border: 2px solid #121313; /* Stroke effect */
}

/* Scrollbar handle hover effect */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #000000, #000000);
}

/* Works in Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #000000 #121313; /* Thumb color, track color */
}
.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 1s ease-in-out;
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  97% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-faq {
  animation: fadeInFaq 3s ease-in-out;
}

@keyframes fadeInFaq {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.zero-height {
  height: 0px;
  opacity: 0;
  display: none;
}

@media (max-width: 768px) {
  .hero-video {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
      // padding: 0 12%;
    }
  }
  .vendor-sec-sec .top .white-dot-circle {
    width: 8px;
  }
  .vendor-sec-sec-scrl-tbs .tab-container {
    display: none !important;
  }
  .vendor-sec-sec.txt .top .heading {
    font-size: 24px;
  }
  .faq-accordion {
    margin-top: 40px;
    padding-bottom: 0;
  }

  .why-section {
    padding: 40px;
    height: auto;
    .head {
      span.heading {
        font-size: 24px;
        line-height: 32px;
      }
      span.sub-heading {
        font-size: 20px;
        line-height: 32px;
      }
    }
    p.paragraph {
      font-size: 16px;
      line-height: 24px;
      padding-top: 12px;
      width: 100%;
    }
    .tble {
      height: auto;
      width: 100%;
      flex-direction: column;
      margin-top: 60px;
      .first,
      .second {
        margin: 0 12px 20px;
      }
      .third {
        margin: 0;
      }
      .first,
      .second,
      .third {
        width: 100%;
        p {
          width: 100% !important;
        }
      }
      p.top-heading {
        top: -42px;
        color: white !important;
      }
    }
    .download-conatiner {
      width: 100%;
      justify-content: center;
    }
  }
  .carrentel-sec {
    min-height: auto;
    padding: 60px 0;

    .sb_hd {
      h2 {
      }
    }

    // &.carrentel-sec_ryt {
    //   .sb_hd {
    //     max-width: ;
    //     margin-left: auto;
    //     text-align: end;

    //     h2::after {
    //       left: unset;
    //       right: 0;
    //     }
    //   }
    // }
  }
}
@media (max-width: 600px) {
  .vendor-first-sec {
    background-position: center bottom;
    padding-top: 80px;
    min-height: auto;
    .top {
      padding: 40px 12%;
      height: 50vh;
      .heading {
        font-size: 32px;
        line-height: 42px;
      }
      span.sub-heading {
        font-size: 20px;
        line-height: 24px;
      }
      p.paragraph {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .bg-car {
      height: 50vh;
    }
  }

  .vendor-sec-sec {
    .top {
      .white-dot-circle {
        height: 4px;
        width: 4px;
        margin: 8px;
      }
      .heading {
        font-size: 26px;
        line-height: 42px;
      }
    }
    .flx {
      .bg-mobile-left {
        figure {
          img {
            width: 100%;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .vendor-sec-sec.txt .top .heading {
    font-size: 12px;
    line-height: 42px;
  }

  .vendor-third-sec {
    height: auto;
    .conta_iner {
      height: auto;
    }
    .top {
      padding-top: 40px;

      span.heading {
        font-size: 26px;
      }
    }
    .tble {
      height: auto;
      margin-top: 40px;
      flex-direction: column;
      .first,
      .second {
        margin: 12px 0;
        border-bottom: 1px solid #222222;
        border-left: unset;
        border-right: unset;
        padding-top: 12px;
      }
      .third {
        margin: 12px 0;
        border-left: unset;
        border-right: unset;
        padding-top: 12px;
      }
      .first,
      .second,
      .third {
        width: 100%;
        p {
          width: 100%;
        }
      }
    }
    .bg-shade-btm {
      height: auto;
    }
  }
}
